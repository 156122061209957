import { gql } from '@apollo/client';

export default gql`
  mutation inviteUser($email: String!) {
    inviteUser(email: $email) {
      __typename
      ... on OperationResult {
        message
      }
    }
  }
`;
