import { gql } from '@apollo/client';

export default gql`
  mutation BatchCreateAnalyse($input: [BatchCreateAnalyseInput]!) {
    batchCreateAnalyse(input: $input) {
      __typename

      ... on OperationResult {
        message
      }

      ... on TokenError {
        message
      }

      ... on Unauthorized {
        message
      }

      ... on WrongInputs {
        errors {
          error
          input
        }
      }
    }
  }
`;
