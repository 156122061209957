import { useCallback, useState } from 'react';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useAtom } from 'jotai';

import BATCH_CREATE_ANALYSIS_MUTATION from '../../api/batch-create-analysis/q-batch-create-analysis';
import { BATCH_CREATE_ANALYSIS_RESPONSE } from '../../api/batch-create-analysis/r-batch-create-analysis';
import GET_ALL_ANALYSIS_QUERY from '../../api/get-all-analysis/q-get-all-analysis';
import { GET_ALL_ANALYSIS_RESPONSE } from '../../api/get-all-analysis/r-get-all-analysis';
import userState, { getInitialState } from './index';
import {
  notificationError,
  notificationSuccess,
  notificationWarning,
} from '../../utils/notification';

export const useUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const client = useApolloClient();
  const [user, setUser] = useAtom(userState);
  const [batchCreateAnalysisMutation] = useMutation(
    BATCH_CREATE_ANALYSIS_MUTATION
  );
  const [getAllAnalysisQuery] = useLazyQuery(GET_ALL_ANALYSIS_QUERY);

  const signout = useCallback(() => {
    setUser(getInitialState());
    window.localStorage.clear();
    client.clearStore();
  }, [setUser, client]);

  const getAllAnalysis = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await getAllAnalysisQuery();
      const { __typename: responseType } = data?.allAnalyses;

      switch (responseType) {
        case GET_ALL_ANALYSIS_RESPONSE.Analyses:
          return data.allAnalyses.analyses;

        case GET_ALL_ANALYSIS_RESPONSE.TokenError:
        case GET_ALL_ANALYSIS_RESPONSE.Unauthorized:
        default:
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
          break;
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [getAllAnalysisQuery, signout]);

  const batchCreateAnalysis = useCallback(
    async (input) => {
      try {
        setIsLoading(true);

        const { data } = await batchCreateAnalysisMutation({
          variables: { input },
        });

        switch (data?.batchCreateAnalyse?.__typename) {
          case BATCH_CREATE_ANALYSIS_RESPONSE.OperationResult:
            notificationSuccess('Analyses envoyées avec succès.');
            return true;

          case BATCH_CREATE_ANALYSIS_RESPONSE.TokenError:
          case BATCH_CREATE_ANALYSIS_RESPONSE.Unauthorized:
            return false;
          case BATCH_CREATE_ANALYSIS_RESPONSE.WrongInputs:
            data.batchCreateAnalyse.errors.forEach(
              ({ error, input }: { error: string; input: string }) => {
                notificationError(`${error} on file at index ${input}`);
              }
            );
            return false;
          default:
            console.error('Faire apparaitre un toaster:', data);
            return false;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [batchCreateAnalysisMutation]
  );

  return {
    batchCreateAnalysis,
    error,
    getAllAnalysis,
    isLoading,
    signout,
    user,
  };
};
