import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

import {
  TextColor,
  TextLineheight,
  TextElement,
  TextWeight,
  TextSize,
} from '../../../types/UIComponents';
import styles from './text.module.scss';

interface TextProps extends HTMLAttributes<HTMLElement> {
  as: TextElement;
  className?: string;
  color?: TextColor;
  htmlFor?: string;
  isCaps?: boolean;
  isCentered?: boolean;
  isFluid?: boolean;
  isOverflow?: boolean;
  lineheight?: TextLineheight;
  size: TextSize;
  weight?: TextWeight;
}

export const Text = forwardRef<HTMLElement, PropsWithChildren<TextProps>>(
  (
    {
      as,
      color = TextColor.DARK,
      weight,
      size,
      isOverflow = false,
      isFluid = false,
      isCentered = false,
      isCaps = false,
      lineheight = TextLineheight.BASE,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const Component = as;
    const textClass = classNames({
      [styles[as]]: true,
      [styles[color]]: true,
      [styles[weight ?? '']]: Boolean(weight),
      [styles[lineheight]]: true,
      [styles[size ?? '']]: Boolean(size),
      [styles.centered]: isCentered,
      [styles.fluid]: isFluid,
      [styles.overflow]: isOverflow,
      [styles.caps]: isCaps,
      [styles.italic]: weight === TextWeight.ITALIC,
      ...(className ? { [className]: true } : {}),
    });

    return (
      <Component ref={ref as any} className={textClass} {...props}>
        {children}
      </Component>
    );
  }
);
