import classNames from 'classnames';
import styles from './loader.module.scss';

export enum LoaderSize {
  X_SMALL = 'XSmall',
  SMALL = 'Small',
  BASE = 'Base',
}

interface LoaderProps {
  size?: LoaderSize;
  isFixedCenter?: boolean;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({
  isFixedCenter,
  className,
  size = LoaderSize.BASE,
}) => {
  const wrapperClass = classNames({
    [styles.wrapper]: true,
    [styles.wrapperSmall]: size === LoaderSize.SMALL,
    [styles.wrapperXSmall]: size === LoaderSize.X_SMALL,
    [styles.wrapperFixedCenter]: isFixedCenter,
    ...(className ? { [className]: true } : {}),
  });

  return <div className={wrapperClass}></div>;
};

export default Loader;
