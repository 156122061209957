import { atomWithStorage } from 'jotai/utils';

const getInitialState = () => ({
  id: '',
  email: '',
  token: '',
  firstname: '',
  lastname: '',
  isAdmin: false,
});

const userState = atomWithStorage('user', getInitialState());

export default userState;
export { getInitialState };
