import classNames from 'classnames';
import styles from './mainLayout.module.scss';
import { ToastContainer } from 'react-toastify';

const MainLayout = ({ children, className, ...props }) => {
  const mainLayoutClass = classNames({
    [styles.wrapper]: true,
    ...(className ? { [className]: true } : {}),
  });

  return (
    <main {...props} className={mainLayoutClass}>
      <ToastContainer />
      {children}
    </main>
  );
};

export default MainLayout;
