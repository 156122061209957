import { useMutation, useApolloClient, useLazyQuery } from '@apollo/client';
import { useState, useCallback } from 'react';
import { useAtom } from 'jotai';

import userState, { getInitialState } from './index';
import {
  notificationError,
  notificationWarning,
} from '../../utils/notification';
import LOGIN_MUTATION from '../../api/login/q-login';
import LOGIN_RESPONSE from '../../api/login/r-login';
import SIGNUP_MUTATION from '../../api/signup/q-signup';
import SIGNUP_RESPONSE from '../../api/signup/r-signup';
import VALIDATE_EMAIL_MUTATION from '../../api/validate-email/q-validate-email';
import VALIDATE_EMAIL_RESPONSE from '../../api/validate-email/r-validate-email';
import FARMS_QUERY from '../../api/farm-plots/q-farm-plots';
import GET_FARMS_QUERY_RESPONSE from '../../api/farm-plots/r-farm-plots';
import CREATE_FARM_PLOT_MUTATION from '../../api/create-farm-plot/q-create-farm-plot';
import CREATE_FARM_PLOT_RESPONSE from '../../api/create-farm-plot/r-create-farm-plot';
import CREATE_FARM_MUTATION from '../../api/create-farm/q-create-farm';
import CREATE_FARM_RESPONSE from '../../api/create-farm/r-create-farm';
import CREATE_ANALYSE_MUTATION from '../../api/create-analyse/q-create-analyse';
import CREATE_ANALYSE_RESPONSE from '../../api/create-analyse/r-create-analyse';
import SEND_ANALYSE_MUTATION from '../../api/send-analyse/q-send-analyse';
import SEND_ANALYSE_RESPONSE from '../../api/send-analyse/r-send-analyse';
import ANALYSE_QUERY from '../../api/get-analyse/q-get-analyse';
import GET_ANALYSE_RESPONSE from '../../api/get-analyse/r-get-analyse';
import SEND_INVITATION_MUTATION from '../../api/send-invitation/q-send-invitation';
import SEND_INVITATION_RESPONSE from '../../api/send-invitation/r-send-invitation';
import UPDATE_ANALYSE_MUTATION from '../../api/update-analyse/q-update-analyse';
import UPDATE_ANALYSE_RESPONSE from '../../api/update-analyse/r-update-analyse';
import UPDATE_USER_MUTATION from '../../api/update-user/q-update-user';
import UPDATE_USER_RESPONSE from '../../api/update-user/r-update-user';
import DELETE_ANALYSE_MUTATION from '../../api/delete-analyse/q-delete-analyse';
import DELETE_ANALYSE_RESPONSE from '../../api/delete-analyse/r-delete-analyse';
import ADMIN_DELETE_ANALYSE_MUTATION from '../../api/admin-delete-analyse/q-admin-delete-analyse';
import ADMIN_DELETE_ANALYSE_RESPONSE from '../../api/admin-delete-analyse/r-admin-delete-analyse';
import GET_SUMMARY_QUERY from '../../api/summary/q-summary';
import { useAnalysis } from '../analysis';

const useUser = () => {
  const [user, setUser] = useAtom(userState);
  const { resetAnalysisMetadata, resetAnalysisInput } = useAnalysis();
  const [userError, setUserError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [signupMutation] = useMutation(SIGNUP_MUTATION);
  const [validateEmailMutation] = useMutation(VALIDATE_EMAIL_MUTATION);
  const [createFarmPlotMutation] = useMutation(CREATE_FARM_PLOT_MUTATION);
  const [createFarmMutation] = useMutation(CREATE_FARM_MUTATION);
  const [createAnalyseMutation] = useMutation(CREATE_ANALYSE_MUTATION);
  const [deleteAnalyseMutation] = useMutation(DELETE_ANALYSE_MUTATION);
  const [adminDeleteAnalyseMutation] = useMutation(
    ADMIN_DELETE_ANALYSE_MUTATION
  );
  const [updateAnalyseMutation] = useMutation(UPDATE_ANALYSE_MUTATION);
  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);
  const [sendAnalyseMutation] = useMutation(SEND_ANALYSE_MUTATION);
  const [sendInvitationMutation] = useMutation(SEND_INVITATION_MUTATION);
  const [getAnalysisQuery] = useLazyQuery(ANALYSE_QUERY);
  const [getFarmsQuery] = useLazyQuery(FARMS_QUERY);
  const [getSummaryQuery] = useLazyQuery(GET_SUMMARY_QUERY);

  const client = useApolloClient();
  const { cache } = client;

  const signout = useCallback(() => {
    setUser(getInitialState());
    window.localStorage.clear();
    client.clearStore();
  }, [setUser, client]);

  const signin = useCallback(
    async (inputs) => {
      setIsLoading(true);
      setUserError('');

      try {
        const { data } = await loginMutation({ variables: { inputs } });

        switch (data?.login?.__typename) {
          case LOGIN_RESPONSE.User:
            setUser(data.login);
            return true;

          case LOGIN_RESPONSE.WrongPassword:
          case LOGIN_RESPONSE.UserNotFound:
          case LOGIN_RESPONSE.EmailNotValidate:
            setUserError(data?.login?.__typename);
            return false;
          default:
            console.error('Faire apparaitre un toaster:', data);
            return false;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setUser, loginMutation, setIsLoading, setUserError]
  );

  const signup = useCallback(
    async (inputs) => {
      setIsLoading(true);
      let success = false;
      let response = '';

      try {
        const { data } = await signupMutation({ variables: { inputs } });

        response = data?.signup?.__typename;

        switch (response) {
          case SIGNUP_RESPONSE.OperationResult:
            success = true;
            break;

          case SIGNUP_RESPONSE.WrongEmail:
          case SIGNUP_RESPONSE.WrongPhonenumber:
          case SIGNUP_RESPONSE.WrongPassword:
          case SIGNUP_RESPONSE.Unauthorized:
            success = false;
            break;
          default:
            console.error('Unexpected error', data);
            success = false;
            break;
        }
      } finally {
        setIsLoading(false);
        return { success, response };
      }
    },
    [signupMutation, setIsLoading]
  );

  const validateEmail = useCallback(async () => {
    setIsLoading(true);
    let success = false;
    let response = '';

    try {
      const { data } = await validateEmailMutation();

      response = data?.EmailValidation?.__typename;
      if (response === VALIDATE_EMAIL_RESPONSE.User) {
        success = true;
        setUser(data.EmailValidation);
      } else {
        success = false;
      }
    } finally {
      setIsLoading(false);
      return { success, response };
    }
  }, [setUser, validateEmailMutation, setIsLoading]);

  const createFarmPlot = useCallback(
    async (input) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await createFarmPlotMutation({ variables: { input } });
        const typename = data?.createFarmPlot?.__typename;

        switch (typename) {
          case CREATE_FARM_PLOT_RESPONSE.FarmPlot:
            success = true;
            response = data.createFarmPlot;

            cache.modify({
              id: cache.identify({ __typename: 'Farm', ID: input.farmId }),
              fields: {
                farmPlots(previousFarmPlots) {
                  return previousFarmPlots.concat({
                    ...response,
                    __typename: 'FarmPlot',
                  });
                },
              },
            });

            break;

          case CREATE_FARM_PLOT_RESPONSE.NameAlreadyTaken:
          default:
            success = false;
            response = typename;
            notificationError(
              'Une parcelle possède déjà ce nom. Veuillez donner un autre nom à votre parcelle.'
            );
            break;
        }

        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, createFarmPlotMutation, cache]
  );

  const createFarm = useCallback(
    async (input) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await createFarmMutation({ variables: { input } });
        const typename = data?.createFarm?.__typename;

        if (typename === CREATE_FARM_RESPONSE.Farm) {
          success = true;
          response = data.createFarm;

          cache.updateQuery({ query: FARMS_QUERY }, (data) => ({
            Farms: {
              __typename: 'Farms',
              farms: data.Farms.farms.concat({ ...response }),
            },
          }));
        } else {
          success = false;
          response = typename;
          signout();
        }

        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, createFarmMutation, cache, signout]
  );

  const createAnalyse = useCallback(
    async (input, file) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await createAnalyseMutation({
          variables: { input, file },
        });

        const typename = data?.createAnalyse?.__typename;

        if (typename === CREATE_ANALYSE_RESPONSE.Result) {
          success = true;
          response = data.createAnalyse;
          cache.modify({
            id: cache.identify({ __typename: 'Summary' }),
            fields: {
              summaries(previousSummaries) {
                return previousSummaries.concat({
                  __typename: 'AnalyseSummary',
                  analyseID: response.analyseID,
                  farmName: input.farmName,
                  plotName: input.plotName,
                  size: input.areaSize,
                  date: input.date,
                  timestamp: new Date(),
                });
              },
            },
          });
          resetAnalysisInput();
          resetAnalysisMetadata();
        } else if (typename === CREATE_ANALYSE_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [
      createAnalyseMutation,
      cache,
      resetAnalysisInput,
      resetAnalysisMetadata,
      signout,
    ]
  );

  const getAnalysis = useCallback(
    async (analysisId) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await getAnalysisQuery({
          variables: { id: analysisId },
        });

        const typename = data?.analyse?.__typename;

        if (typename === GET_ANALYSE_RESPONSE.Analyse) {
          success = true;
          response = data.analyse;
        } else if (typename === GET_ANALYSE_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [getAnalysisQuery, signout]
  );

  const getFarms = useCallback(async () => {
    setIsLoading(true);

    let success = false;
    let response = null;

    try {
      const { data } = await getFarmsQuery();

      const typename = data?.Farms?.__typename;

      if (typename === GET_FARMS_QUERY_RESPONSE.Farms) {
        success = true;
        response = data.Farms.farms;
      } else if (typename === GET_FARMS_QUERY_RESPONSE.TokenError) {
        success = false;
        response = typename;
        notificationWarning('Session expirée, veuillez vous re-connecter');
        signout();
      } else {
        success = false;
        response = typename;
      }
      return { success, response };
    } catch (e) {
      return { success: false, response: e };
    } finally {
      setIsLoading(false);
    }
  }, [getFarmsQuery, signout]);

  const deleteAnalyse = useCallback(
    async (id) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await deleteAnalyseMutation({ variables: { id } });

        const typename = data?.deleteAnalyse?.__typename;

        if (typename === DELETE_ANALYSE_RESPONSE.OperationResult) {
          success = true;
          response = data.deleteAnalyse;
          cache.modify({
            id: cache.identify({ __typename: 'Summary' }),
            fields: {
              summaries(previousSummaries) {
                return previousSummaries.filter(
                  ({ analyseID }) => analyseID !== id
                );
              },
            },
          });
        } else if (typename === DELETE_ANALYSE_RESPONSE.TokenError) {
          signout();
          notificationWarning('Session expirée, veuillez vous re-connecter');
          success = true;
          response = typename;
        } else {
          success = false;
          response = typename;
        }

        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, deleteAnalyseMutation, cache, signout]
  );

  const adminDeleteAnalyse = useCallback(
    async (id) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await adminDeleteAnalyseMutation({
          variables: { id },
        });

        const typename = data?.adminDeleteAnalyse?.__typename;

        if (typename === ADMIN_DELETE_ANALYSE_RESPONSE.OperationResult) {
          success = true;
          response = data.adminDeleteAnalyse;
          cache.modify({
            id: cache.identify({ __typename: 'AdminSummary' }),
            fields: {
              summaries(previousSummaries) {
                return previousSummaries.filter(
                  ({ analyseID }) => analyseID !== id
                );
              },
            },
          });
        } else if (typename === ADMIN_DELETE_ANALYSE_RESPONSE.TokenError) {
          signout();
          notificationWarning('Session expirée, veuillez vous re-connecter');
          success = true;
          response = typename;
        } else {
          success = false;
          response = typename;
        }

        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, adminDeleteAnalyseMutation, cache, signout]
  );

  const sendAnalyse = useCallback(
    async (id) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await sendAnalyseMutation({ variables: { id } });

        const typename = data?.sendAnalyse?.__typename;

        if (typename === SEND_ANALYSE_RESPONSE.OperationResult) {
          success = true;
        } else if (typename === SEND_ANALYSE_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, sendAnalyseMutation, signout]
  );

  const sendInvitation = useCallback(
    async (email) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await sendInvitationMutation({ variables: { email } });

        const typename = data?.inviteUser?.__typename;

        if (typename === SEND_INVITATION_RESPONSE.OperationResult) {
          success = true;
        } else if (typename === SEND_INVITATION_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, sendInvitationMutation, signout]
  );

  const updateAnalyse = useCallback(
    async (input) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await updateAnalyseMutation({ variables: { input } });
        const typename = data?.updateAnalyse?.__typename;

        if (typename === UPDATE_ANALYSE_RESPONSE.Result) {
          success = true;
          response = data.updateAnalyse;
          input.ID = input.analyseID;
          delete input.analyseID;

          for (let key in input) {
            if (typeof input[key] !== 'string') {
              continue;
            }
            input[key] = input[key].toLowerCase();
          }

          client.writeQuery({
            query: ANALYSE_QUERY,
            data: {
              analyse: {
                __typename: 'Analyse',
                ...input,
              },
            },
            variables: {
              id: input.analyseID,
            },
          });
        } else if (typename === UPDATE_ANALYSE_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, updateAnalyseMutation, client, signout]
  );

  const updateUser = useCallback(
    async (input) => {
      setIsLoading(true);

      let success = false;
      let response = null;

      try {
        const { data } = await updateUserMutation({ variables: { input } });
        const typename = data?.updateUser?.__typename;

        if (typename === UPDATE_USER_RESPONSE.User) {
          success = true;
          setUser(data.updateUser);
        } else if (typename === UPDATE_USER_RESPONSE.TokenError) {
          success = false;
          response = typename;
          notificationWarning('Session expirée, veuillez vous re-connecter');
          signout();
        } else {
          success = false;
          response = typename;
        }
        return { success, response };
      } catch (e) {
        return { success: false, response: e };
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, updateUserMutation, setUser, signout]
  );

  return {
    user,
    setUserError,
    setUser,
    userError,
    isLoading,
    getAnalysis,
    getFarms,
    signin,
    signup,
    signout,
    validateEmail,
    createFarmPlot,
    createAnalyse,
    updateAnalyse,
    sendAnalyse,
    sendInvitation,
    updateUser,
    deleteAnalyse,
    adminDeleteAnalyse,
    createFarm,
  };
};

export default useUser;
