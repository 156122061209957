import { gql } from '@apollo/client';

export default gql`
  query analyse($id: String!) {
    analyse(id: $id) {
      __typename
      ... on Analyse {
        ID
        phWater
        phKcl
        organicMatter
        nitrogen
        ratioCN
        phosphorus
        potassium
        calcium
        sodium
        magnesium
        lab
        ratioCn
        isElementOxyde
        isCalcareousGround
        identifiedProblem
        dominantCulture
        texture
        cultureType
        cationExchangeCapacity
        copper
        zinc
        manganese
        iron
        boron
        sulfate
        sulfateUnit
        boronUnit
        ironUnit
        manganeseUnit
        zincUnit
        copperUnit
        cationExchangeCapacityUnit
        magnesiumUnit
        sodiumUnit
        calciumUnit
        potassiumUnit
        phosphorusUnit
        phosphorusType
        organicMatterUnit
        nitrogenUnit
      }
    }
  }
`;
