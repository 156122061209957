import classNames from 'classnames';
import { useState, useCallback } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { slide as BurgerMenu } from 'react-burger-menu';

import { Icon } from '../../../components/atoms/icon';
import { Text } from '../../../components/atoms/text';
import styles from './menu.module.scss';
import useUser from '../../../store/user/useUser';
import {
  IconColor,
  IconSize,
  IconStyle,
  IconVariant,
  TextElement,
  TextLineheight,
  TextSize,
} from '../../../types/UIComponents';

const MenuType = ({ children, className, isMenuOpen, setIsMenuOpen }) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 575;

  return isMobile ? (
    <BurgerMenu
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
    >
      {children}
    </BurgerMenu>
  ) : (
    <menu
      className={classNames({
        [styles.wrapper]: true,
        ...(className ? { [className]: true } : {}),
      })}
    >
      <Icon
        variant={IconVariant.GAIAGO_LOGO_MENU}
        color={IconColor.LIGHT_GREEN}
        size={IconSize.SMALL}
        iconStyle={IconStyle.SOLID}
        className={styles.icon}
        onClick={() => navigate('/')}
      />
      <div className={styles.links}>{children}</div>
    </menu>
  );
};

const Menu = () => {
  const { signout, user } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const exit = useCallback(() => {
    setIsMenuOpen(false);
    signout();
  }, [signout, setIsMenuOpen]);

  return (
    <MenuType isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
      <NavLink
        className={({ isActive }) =>
          classNames({
            [styles.analyses]: true,
            [styles.active]:
              isActive || window.location.href.includes('analyse'),
          })
        }
        to="/"
        onClick={() => setIsMenuOpen(false)}
      >
        <Text
          as={TextElement.SPAN}
          size={TextSize.MEDIUM}
          lineheight={TextLineheight.LARGE}
        >
          Analyses
        </Text>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          classNames({
            [styles.analyses]: true,
            [styles.active]: isActive,
          })
        }
        to="/profile"
        onClick={() => setIsMenuOpen(false)}
      >
        <Text
          as={TextElement.SPAN}
          size={TextSize.MEDIUM}
          lineheight={TextLineheight.LARGE}
        >
          Profil
        </Text>
      </NavLink>
      {user.isAdmin && (
        <NavLink
          className={({ isActive }) =>
            classNames({
              [styles.analyses]: true,
              [styles.active]: isActive,
            })
          }
          to="/admin"
          onClick={() => setIsMenuOpen(false)}
        >
          <Text
            as={TextElement.SPAN}
            size={TextSize.MEDIUM}
            lineheight={TextLineheight.LARGE}
          >
            Admin
          </Text>
        </NavLink>
      )}
      <Text
        className={styles.profile}
        as={TextElement.SPAN}
        size={TextSize.MEDIUM}
        lineheight={TextLineheight.LARGE}
        onClick={exit}
      >
        Déconnexion
      </Text>
    </MenuType>
  );
};

export default Menu;
