import { gql } from '@apollo/client';

export default gql`
  query Farms {
    Farms {
      __typename

      ... on Farms {
        farms {
          ID
          name
          address
          city
          postalCode
          gaiagoFarmId
          distributor {
            id
            name
          }
          farmPlots {
            address
            areaSize
            city
            gaiagoFarmPlotId
            ID
            name
            postalCode
          }
        }
      }
    }
  }
`;
