import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Text } from '../../../components/atoms/text';
import {
  TextElement,
  TextLineheight,
  TextSize,
  TextWeight,
} from '../../../types/UIComponents';
import styles from './footer.module.scss';

const Footer = ({ className }) => {
  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        ...(className ? { [className]: true } : {}),
      })}
    >
      <Link to="/cgu">
        <Text
          className={styles.text}
          as={TextElement.SPAN}
          size={TextSize.X_SMALL}
          lineheight={TextLineheight.LARGE}
          weight={TextWeight.ITALIC}
        >
          Conditions Générales d’Utilisation de l’outil
        </Text>
      </Link>
    </div>
  );
};

export default Footer;
