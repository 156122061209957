import { gql } from '@apollo/client';

export default gql`
  mutation CreateFarmPlot($input: CreateFarmPlotInput!) {
    createFarmPlot(input: $input) {
      __typename

      ... on FarmPlot {
        ID
        name
        address
        city
        postalCode
        areaSize
        gaiagoFarmPlotId
      }
    }
  }
`;
