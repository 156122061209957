import { gql } from '@apollo/client';

export default gql`
  mutation login($inputs: LoginInput!) {
    login(input: $inputs) {
      __typename
      ... on User {
        token
        id
        email
        distributorName
        phoneNumber
        firstname
        lastname
        isAdmin
      }
    }
  }
`;
