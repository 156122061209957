import Loader from '../../../components/atoms/loader';
import styles from './lazyLoaderFallback.module.scss';

const LazyLoaderFallback = () => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

export default LazyLoaderFallback;
