const UPDATE_USER_RESPONSE = {
  User: 'User',
  WrongPassword: 'WrongPassword',
  WrongEmail: 'WrongEmail',
  WrongPhonenumber: 'WrongPhonenumber',
  Unauthorized: 'Unauthorized',
  TokenError: 'TokenError',
};

export default UPDATE_USER_RESPONSE;
