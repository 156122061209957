import { gql } from '@apollo/client';

export default gql`
  mutation sendAnalyse($id: String!) {
    sendAnalyse(id: $id) {
      __typename
      ... on OperationResult {
        message
      }
    }
  }
`;
