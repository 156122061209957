import { gql } from '@apollo/client';

export default gql`
  query allAnalyses {
    allAnalyses {
      __typename

      ... on Analyses {
        analyses {
          boron
          boronUnit
          calcium
          calciumUnit
          cationExchangeCapacity
          cationExchangeCapacityUnit
          copper
          copperUnit
          cultureType
          date
          dominantCulture
          ID
          identifiedProblem
          iron
          ironUnit
          isCalcareousGround
          isElementOxyde
          lab
          magnesium
          magnesiumUnit
          manganese
          manganeseUnit
          nitrogen
          nitrogenUnit
          organicMatter
          organicMatterUnit
          phKcl
          phosphorus
          phosphorusType
          phosphorusUnit
          phWater
          potassium
          potassiumUnit
          ratioCn
          ratioCN
          sodium
          sodiumUnit
          sulfate
          sulfateUnit
          texture
          zinc
          zincUnit
        }
      }

      ... on TokenError {
        message
      }

      ... on Unauthorized {
        message
      }
    }
  }
`;
