import { gql } from '@apollo/client';

export default gql`
  mutation signup($inputs: SignupInput!) {
    signup(input: $inputs) {
      __typename
      ... on OperationResult {
        message
      }
    }
  }
`;
