import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useLazyQuery } from '@apollo/client';
// import { useMutation } from '@apollo/client';

// import CREATE_ANALYSE_MUTATION from '../../api/create-analyse/q-create-analyse';
// import DELETE_ANALYSE_MUTATION from '../../api/delete-analyse/q-delete-analyse';
// import { CREATE_ANALYSE_RESPONSE } from '../../api/create-analyse/r-create-analyse';
// import { DELETE_ANALYSE_RESPONSE } from '../../api/delete-analyse/r-delete-analyse';
import {
  getAnalysisInputDefaultState,
  getAnalysisMetadataDefaultState,
} from './state';
import { GET_DISTRIBUTORS_RESPONSE } from '../../api/get-distributors/r-get-distributors';
import GET_DISTRIBUTORS_QUERY from '../../api/get-distributors/q-get-distributors';
import { notificationError } from '../../utils/notification';

// import {
//   AnalyseSummary,
//   CreateAnalyseInput,
//   CreateAnalyseMutation,
//   CreateAnalyseMutationVariables,
//   DeleteAnalyseMutation,
//   DeleteAnalyseMutationVariables,
//   Summary,
// } from '../../modules/graphQL/__generated__/graphql';
// import { notificationError } from '../../utils/notification';
// import { useFarm } from '../farm';

const inputState = atomWithStorage(
  'user.analysis.input',
  getAnalysisInputDefaultState()
);

const metadataState = atomWithStorage(
  'user.analysis.metadata',
  getAnalysisMetadataDefaultState()
);

export const useAnalysis = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const { farm, farmPlot } = useFarm();
  // const [analysisSummaries, setAnalysisSummaries] = useState<AnalyseSummary[]>(
  //   []
  // );
  const [analysisInput, setAnalysisInput] = useAtom(inputState);
  const [analysisMetadata, setAnalysisMetadata] = useAtom(metadataState);
  const [getDistributorsQuery] = useLazyQuery(GET_DISTRIBUTORS_QUERY);

  // const [createAnalyseMutation] = useMutation<
  //   CreateAnalyseMutation,
  //   CreateAnalyseMutationVariables
  // >(CREATE_ANALYSE_MUTATION);
  // const [deleteAnalyseMutation] = useMutation<
  //   DeleteAnalyseMutation,
  //   DeleteAnalyseMutationVariables
  // >(DELETE_ANALYSE_MUTATION);

  const resetAnalysisInput = useCallback(() => {
    setAnalysisInput(getAnalysisInputDefaultState());
  }, [setAnalysisInput]);

  const resetAnalysisMetadata = useCallback(() => {
    setAnalysisMetadata(getAnalysisMetadataDefaultState());
  }, [setAnalysisMetadata]);

  const getDistributors = useCallback(async () => {
    const { data } = await getDistributorsQuery();
    const responseTypename = data?.distributors?.__typename;

    switch (responseTypename) {
      case GET_DISTRIBUTORS_RESPONSE.Distributors:
        return data.distributors.distributors;

      case GET_DISTRIBUTORS_RESPONSE.TokenError:
      default:
        notificationError(responseTypename);
        throw Error(responseTypename);
    }
  }, [getDistributorsQuery]);

  // const createAnalysis = useCallback(
  //   async (input: CreateAnalyseInput) => {
  //     setIsLoading(true);

  //     try {
  //       const { data } = await createAnalyseMutation({ variables: { input } });
  //       const responseTypename = data?.createAnalyse?.__typename;

  //       switch (responseTypename) {
  //         case CREATE_ANALYSE_RESPONSE.Result:
  //           // const newSummary: Summary = {
  //           //   __typename: 'Summary',
  //           //   analyseId: data?.createAnalyse?.id,
  //           //   farmName: farm.name,
  //           //   plotName: farmPlot.name,
  //           //   size: farmPlot.areaSize,
  //           //   date: input.date,
  //           //   timestamp: new Date(),
  //           // };

  //           // setAnalysisSummaries((summaries) => [...summaries, newSummary]);
  //           break;

  //         case CREATE_ANALYSE_RESPONSE.WrongInputs:
  //         case CREATE_ANALYSE_RESPONSE.Unauthorized:
  //         case CREATE_ANALYSE_RESPONSE.TokenError:
  //         default:
  //           notificationError(responseTypename);
  //           throw Error(responseTypename);
  //       }
  //     } catch (error) {
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [createAnalyseMutation]
  // );

  return {
    isLoading,
    setIsLoading,
    analysisInput,
    analysisMetadata,
    getDistributors,
    setAnalysisInput,
    setAnalysisMetadata,
    resetAnalysisInput,
    resetAnalysisMetadata,
    // createAnalysis,
  };
};
