import { gql } from '@apollo/client';

export default gql`
  mutation EmailValidation {
    EmailValidation {
      __typename
      ... on User {
        token
        id
        email
        distributorName
        phoneNumber
        firstname
        lastname
        isAdmin
      }
    }
  }
`;
