import { gql } from '@apollo/client';

export default gql`
  mutation CreateFarm($input: CreateFarmInput!) {
    createFarm(input: $input) {
      __typename

      ... on Farm {
        ID
        name
        address
        city
        postalCode
        gaiagoFarmId
        distributor {
          name
          id
        }
        farmPlots {
          ID
          name
          address
          city
          postalCode
          areaSize
          gaiagoFarmPlotId
        }
      }
    }
  }
`;
