import { useNavigate } from 'react-router-dom';
import { PropsWithChildren, useEffect } from 'react';

import { useUser } from '../store/user/useUser.new';

interface ProtectedRouteProps {
  isAdmin?: boolean;
}

const ProtectedRoute: React.FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  isAdmin = false,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (!user.token) {
      navigate('/login');
    }
  }, [navigate, user.token]);

  useEffect(() => {
    if (isAdmin && !user.isAdmin) {
      navigate('/');
    }
  }, [isAdmin, navigate, user.isAdmin, user.token]);

  return <>{children}</>;
};

export default ProtectedRoute;
