import { gql } from '@apollo/client';

export default gql`
  query distributors {
    distributors {
      __typename

      ... on Distributors {
        distributors {
          name
          id
        }
      }

      ... on TokenError {
        message
      }
    }
  }
`;
