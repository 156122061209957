import { gql } from '@apollo/client';

export default gql`
  mutation adminDeleteAnalyse($id: String!) {
    adminDeleteAnalyse(id: $id) {
      __typename
      ... on OperationResult {
        message
      }
    }
  }
`;
