import { gql } from '@apollo/client';

export default gql`
  query analyseSummary {
    analyseSummary {
      __typename
      ... on Summary {
        summaries {
          analyseID
          farmName
          plotName
          size
          date
          timestamp
        }
      }
    }
  }
`;
