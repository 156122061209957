import { Outlet } from 'react-router-dom';

import Menu from '../../molecules/menu';
import Footer from '../../molecules/footer';
import styles from './menulayout.module.scss';

const MenuLayout = () => {
  return (
    <div className={styles.wrapper}>
      <Menu className={styles.menu} />
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer className={styles.footer} />
    </div>
  );
};

export default MenuLayout;
