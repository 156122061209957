export enum IconVariant {
  ARROW_BOTTOM = 'arrow-bottom',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  BORON_ION = 'boron-ion',
  BORON = 'boron',
  CALCIUM_ELEMENT = 'calcium-element',
  CALCIUM_ION = 'calcium-ion',
  CALCIUM = 'calcium',
  CEC = 'cec',
  CHECK_OUTLINE = 'check-outline',
  COPPER_ION = 'copper-ion',
  COPPER = 'copper',
  CROSS = 'cross',
  DELETE = 'delete',
  DROPDOWN = 'dropdown',
  EMAIL_OUTLINE = 'email-outline',
  EXPORT_TO_FILE_OUTLINE = 'export-to-file-outline',
  GAIAGO_LOGO_MENU = 'gaiago-logo-menu',
  GAIAGO_LOGO = 'gaiago-logo',
  GEOLOCALISATION = 'geo-localisation',
  IRON_ION = 'iron-ion',
  IRON = 'iron',
  LABS = 'labs',
  MAGNESIUM_ELEMENT = 'magnesium-element',
  MAGNESIUM_ION = 'magnesium-ion',
  MAGNESIUM = 'magnesium',
  MANGANESE_ION = 'manganese-ion',
  MANGANESE = 'manganese',
  NITROGEN = 'nitrogen',
  ORGANIC_MATTER = 'organic-matter',
  PARCEL = 'parcel',
  PASSWORD_OUTLINE = 'password-outline',
  PERSON_OUTLINE = 'person-outline',
  PH_KCL = 'ph-kcl',
  PH_WATER = 'ph-water',
  PHONE_OUTLINE = 'phone-outline',
  PHOSPHORUS = 'phosphorus',
  POTASSIUM_ELEMENT = 'potassium-element',
  POTASSIUM_ION = 'potassium-ion',
  POTASSIUM = 'potassium',
  RATIO_CN = 'ratio-cn',
  RESULT = 'result',
  REVEAL_OUTLINE = 'reveal-outline',
  SIZE = 'size',
  SODIUM_ELEMENT = 'sodium-element',
  SODIUM_ION = 'sodium-ion',
  SODIUM = 'sodium',
  SUFLATE = 'sulfate',
  UPDATE = 'update',
  ZINC_ION = 'zinc-ion',
  ZINC = 'zinc',
}

export enum IconElement {
  SPAN = 'span',
  BUTTON = 'span',
}

export enum IconColor {
  WHITE = 'white',
  DARK = 'dark',
  BLACK = 'black',
  GREY = 'grey',
  ACTIVE = 'active',
  LIGHT_GREEN = 'light-green',
  RED = 'red',
}

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum IconStyle {
  OUTLINE = 'outline',
  SOLID = 'solid',
}

export enum FieldInputSize {
  SMALL = 50,
  BASE = 200,
}

export enum FieldInputColor {
  DARK = 'dark',
  ACTIVE = 'active',
}

export enum TextElement {
  SPAN = 'span',
  BUTTON = 'button',
  LINK = 'a',
  LABEL = 'label',
  PARAGRAPH = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum TextColor {
  DARK = 'dark',
  WHITE = 'white',
  BLACK = 'black',
  ORANGE = 'orange',
  ACTIVE = 'active',
  GREEN = 'green',
  DARK_GREEN = 'dark-green',
  RED = 'red',
  GREY = 'grey',
  DISABLED = 'disabled',
}

export enum TextLineheight {
  X_SMALL = 'lineheight-xs',
  SMALL = 'lineheight-sm',
  BASE = 'lineheight-base',
  MEDIUM = 'lineheight-md',
  LARGE = 'lineheight-lg',
  X_LARGE = 'lineheight-xl',
}

export enum TextSize {
  XX_SMALL = 'size-xxsmall',
  X_SMALL = 'size-xsmall',
  SMALL = 'size-small',
  NORMAL = 'size-normal',
  BASE = 'size-base',
  MEDIUM = 'size-medium',
  LARGE = 'size-large',
  X_LARGE = 'size-xlarge',
}

export enum TextWeight {
  ITALIC = 'italic',
  LIGHT = 'weight-light',
  BASE = 'weight-base',
  MEDIUM = 'weight-medium',
  BOLD = 'weight-bold',
  ULTRA = 'weight-ultra',
}
