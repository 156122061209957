import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import MainLayout from '../components/layouts/mainLayout';
import MenuLayout from '../components/layouts/menuLayout';
import LazyLoaderFallback from '../components/molecules/lazyLoaderFallback';
import ProtectedRoute from '../utils/protectedRoute';

import '../styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

const Admin = lazy(() =>
  import(
    './admin/index.tsx' /*webpackChunkName: "Admin", webpackPrefetch: true */
  )
);
const AdminUserSummary = lazy(() =>
  import(
    './admin-user-summary' /*webpackChunkName: "AdminUserSummary", webpackPrefetch: true */
  )
);
const AdminUserAnalyse = lazy(() =>
  import(
    './admin-user-analyse' /*webpackChunkName: "AdminUserAnalyse", webpackPrefetch: true */
  )
);
const Profile = lazy(() =>
  import('./profile' /*webpackChunkName: "Profile", webpackPrefetch: true */)
);
const Analyses = lazy(() =>
  import('./analyses' /*webpackChunkName: "Analyses", webpackPrefetch: true */)
);
const ResetPassword = lazy(() =>
  import(
    './reset-password' /*webpackChunkName: "ResetPassword", webpackPrefetch: true */
  )
);
const CreateAnalyse = lazy(() =>
  import(
    './analyse-create' /*webpackChunkName: "CreateAnalyse", webpackPrefetch: true */
  )
);
const UpdateAnalyse = lazy(() =>
  import(
    './analyse-update' /*webpackChunkName: "UpdateAnalyse", webpackPrefetch: true */
  )
);
const Login = lazy(() =>
  import('./login' /* webpackChunkName: "Login", webpackPrefetch: true  */)
);
const Result = lazy(() =>
  import(
    './analyse-result' /* webpackChunkName: "Result", webpackPrefetch: true  */
  )
);
const Signup = lazy(() =>
  import('./signup' /* webpackChunkName: "Signup", webpackPrefetch: true  */)
);
const ValidateEmail = lazy(() =>
  import(
    './validate-email' /* webpackChunkName: "ValidateEmail", webpackPrefetch: true  */
  )
);
const CGU = lazy(() =>
  import(
    './cgu' /* webpackChunkName: "ValidateEmail", webpackPrefetch: true  */
  )
);

const App = () => (
  <Suspense
    fallback={
      <MainLayout>
        <LazyLoaderFallback />
      </MainLayout>
    }
  >
    <MainLayout>
      <Routes>
        <Route
          element={
            <ProtectedRoute>
              <MenuLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Analyses />} />
          <Route path="profile" element={<Profile />} />
          <Route path="analyse/new" element={<CreateAnalyse />} />
          <Route path="analyse/:id" element={<Result />} />
          <Route path="analyse/:id/update" element={<UpdateAnalyse />} />
        </Route>

        <Route
          element={
            <ProtectedRoute admin>
              <MenuLayout />
            </ProtectedRoute>
          }
        >
          <Route path="admin" element={<Admin />} />
          <Route path="admin/:id" element={<AdminUserSummary />} />
          <Route
            path="admin/:userID/:analyseID"
            element={<AdminUserAnalyse />}
          />
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="validate-email/:token" element={<ValidateEmail />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="cgu" element={<CGU />} />

        <Route path="*" element={<h1>Not found</h1>} />
      </Routes>
    </MainLayout>
  </Suspense>
);

export default App;
