import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});
// const link = createHttpLink({
//   uri: 'https://api.beta.gaiagotools.com/graphql',
// });

const authLink = setContext((_, { headers }) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  return {
    headers: {
      ...headers,
      authorization: user.token ? `Bearer ${user.token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Result: {
        keyFields: ['ID'],
      },
      FarmPlot: {
        keyFields: ['ID'],
      },
      Farm: {
        keyFields: ['ID'],
      },
      Farms: {
        keyFields: [],
      },
      Analyse: {
        keyFields: ['ID'],
      },
      Summary: {
        keyFields: [],
      },
      AdminSummary: {
        keyFields: ['summaries'],
      },
    },
  }),
});

export default client;
