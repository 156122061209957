import { HTMLAttributes, forwardRef, useCallback } from 'react';
import classNames from 'classnames';

import {
  IconVariant,
  IconElement,
  IconColor,
  IconSize,
  IconStyle,
} from '../../../types/UIComponents';
import { iconsMap } from './iconsMap';
import styles from './icon.module.scss';

interface IconProps extends HTMLAttributes<HTMLElement> {
  variant: IconVariant;
  as?: IconElement;
  color?: IconColor;
  iconStyle?: IconStyle;
  size?: IconSize;
  className?: string;
}

export const Icon = forwardRef<HTMLElement, IconProps>(
  (
    {
      variant,
      as = IconElement.SPAN,
      color,
      iconStyle = IconStyle.SOLID,
      size = IconSize.MEDIUM,
      className,
      ...props
    },
    ref
  ) => {
    const Component = as;
    const iconTemplate = iconsMap[variant];
    const getHTML = useCallback(
      () => ({ __html: iconTemplate }),
      [iconTemplate]
    );
    const iconClass = classNames({
      [styles.wrapper]: true,
      [styles[size]]: true,
      ...(color ? { [styles[color]]: true } : {}),
      ...(color && iconStyle
        ? { [styles[`${iconStyle}-${color}`]]: true }
        : {}),
      ...(className ? { [className]: true } : {}),
    });

    return iconTemplate ? (
      <Component
        dangerouslySetInnerHTML={getHTML()}
        ref={ref}
        className={iconClass}
        {...props}
      />
    ) : null;
  }
);
