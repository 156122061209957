import { gql } from '@apollo/client';

export default gql`
  mutation CreateAnalyse($input: CreateAnalyseInput!, $file: Upload) {
    createAnalyse(input: $input, file: $file) {
      __typename
      ... on Result {
        ID
        analyseID
        cec
        organicMatter
        phWater
        phKcl
        summaryCalcium
        summaryMagnesium
        summaryPotassium
        summarySodium
        summaryHydrogen
        summaryOther
        perfectRatioCalcium
        perfectRatioMagnesium
        calciumReal
        magnesiumReal
        potassiumReal
        sodiumReal
        phosphorusReal
        boronReal
        ironReal
        manganeseReal
        copperReal
        zincReal
        calciumIdeal
        magnesiumIdeal
        potassiumIdeal
        sodiumIdeal
        phosphorusIdeal
        boronIdeal
        ironIdeal
        manganeseIdeal
        copperIdeal
        zincIdeal
        products {
          name
          years
          max
        }
      }
    }
  }
`;
