export const getAnalysisInputDefaultState = () => ({
  cationExchangeCapacity: '',
  phWater: '',
  phKcl: '',
  organicMatter: '',
  nitrogen: '',
  ratioCN: '',
  phosphorus: '',
  potassium: '',
  calcium: '',
  sodium: '',
  magnesium: '',
  copper: '',
  zinc: '',
  manganese: '',
  iron: '',
  boron: '',
  sulfate: '',
  cationExchangeCapacityUnit: 'MEQ_100G',
  sulfateUnit: 'PPM',
  boronUnit: 'PPM',
  ironUnit: 'PPM',
  manganeseUnit: 'PPM',
  zincUnit: 'PPM',
  copperUnit: 'PPM',
  magnesiumUnit: 'PPM',
  sodiumUnit: 'PPM',
  calciumUnit: 'PPM',
  potassiumUnit: 'PPM',
  phosphorusUnit: 'PPM',
  nitrogenUnit: 'PPM',
  phosphorusType: 'JORET',
  organicMatterUnit: 'PERCENT',
  isElementOxyde: true,
});

export const getAnalysisMetadataDefaultState = () => ({
  lab: '',
  analyseUrl: '',
  file: '',
  identifiedProblem: false,
  date: new Date().getTime(),
  emailAgricultor: null,
  emailTc: null,
  isCalcareousGround: null,
  dominantCulture: null,
});
